<template>
  <div class="field has-addons">
    <a
      @click.prevent="$emit('close')"
      class="icon is-small is-vcentered menu-item"
    >
      <i class="fas fa-times" />
    </a>
    <div
      class="control mx-4"
      v-if="isCompanionUser"
    >
      <a
        @click="selectType('companion')"
        data-test-id="header-companion-button"
      >
        <span class="icon is-small">
          <img
            :src="require('core/img/icons/percayso-logo.svg')"
            width="36"
            height="36"
          >
        </span>
        <span class="menu-item">Companion</span>
      </a>
    </div>
    <div
      class="control mx-4"
      v-if="hasAutoCheck && !hasMotorCheck"
    >
      <a
        @click="selectType('check')"
        data-test-id="header-autocheck-button"
      >
        <span class="icon is-small has-text-soft">
          <i class="fas fa-check" />
        </span>
        <span class="menu-item">AutoCheck</span>
      </a>
    </div>
    <div
      class="control mx-4"
      v-if="hasMotorCheck && !hasAutoCheck"
    >
      <a
        @click="selectType('motorcheck')"
        data-test-id="header-motorcheck-button"
      >
        <span class="icon is-small has-text-soft">
          <i class="fas fa-search" />
        </span>
        <span class="has-text-grey-dark">MotorCheck</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeaderSearchOptions',
  computed: {
    ...mapGetters('auth', [
      'isCompanionUser',
      'hasAutoCheck',
      'hasMotorCheck'
    ]),
    vrm() {
      return this.search.vrm.toUpperCase()
    },
    isExperian() {
      return this.$experian
    },
    options() {
      return []
    }
  },
  methods: {
    selectType(searchType) {
      this.$emit('selectType', searchType)
    }
  }
}
</script>

<style lang="sass" scoped>
.has-addons
  align-items: center
</style>
